import Vue from 'vue'
import Router from 'vue-router'
import WebActivityService from '@/services/WebActivityService'
import AnonymousPageLayout from '@/layouts/AnonymousPageLayout.vue'
import ConfidantPageLayout from '@/layouts/ConfidantPageLayout.vue'
import CustomerPageLayout from '@/layouts/CustomerPageLayout.vue'
import WaveLayout from '@/layouts/WaveLayout.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'Main',
      path: '/',
      redirect: '/login',
    },
    {
      name: '2fa',
      path: '/2fa',
      component: () => import('@/views/2fa/2fa'),
      meta: {
        allowAnonymous: true,
        allowConfidant: false,
        allowCustomer: false,
        layout: WaveLayout,
      },
    },
    {
      name: 'MessageSent',
      path: '/messageSent',
      component: () => import('@/views/anonymousPages/MessageSent'),
      meta: {
        allowAnonymous: true,
        allowConfidant: true,
        allowCustomer: true,
      },
    },
    {
      name: 'InvalidLink',
      path: '/invalidLink/:typeOfLink',
      component: () => import('@/views/anonymousPages/InvalidLink'),
      meta: {
        allowAnonymous: true,
        allowConfidant: true,
        allowCustomer: true,
        layout: WaveLayout,
      },
    },
    {
      name: 'VerifyAccount',
      path: '/verifyAccount/:verificationKey',
      component: () => import('@/views/anonymousPages/VerifyAccount'),
      meta: {
        allowAnonymous: true,
        allowConfidant: true,
        allowCustomer: true,
        layout: WaveLayout,
      },
    },
    {
      name: 'PasswordSuccessfullyReset',
      path: '/resetPassword/success',
      component: () => import('@/views/anonymousPages/PasswordSuccessfullyReset'),
      meta: {
        allowAnonymous: true,
        allowConfidant: true,
        allowCustomer: true,
        layout: WaveLayout,
      },
    },
    {
      name: 'ResetPassword',
      path: '/resetPassword/:resetKey',
      component: () => import('@/views/anonymousPages/ResetPassword'),
      meta: {
        allowAnonymous: true,
        allowConfidant: true,
        allowCustomer: true,
        layout: WaveLayout,
      },
    },
    {
      name: 'NotificationSuccessfullyCancelled',
      path: '/cancelNotification/success',
      component: () => import('@/views/anonymousPages/EmergencyNotificationSuccessfullyCancelled'),
      meta: {
        allowAnonymous: true,
        allowConfidant: true,
        allowCustomer: true,
        layout: WaveLayout,
      },
    },
    {
      name: 'CancelNotification',
      path: '/cancelNotification/:cancellationKey',
      component: () => import('@/views/anonymousPages/CancelEmergencyNotification'),
      meta: {
        allowAnonymous: true,
        allowConfidant: true,
        allowCustomer: true,
        layout: WaveLayout,
      },
    },
    {
      name: 'ConfirmSelfProtection',
      path: '/confirm-selfprotection/:cancellationKey',
      component: () => import('@/views/anonymousPages/ConfirmSelfProtection'),
      meta: {
        allowAnonymous: true,
        allowConfidant: true,
        allowCustomer: true,
        layout: WaveLayout,
      },
    },
    {
      name: 'CancelSelfProtection',
      path: '/cancel-selfprotection/:cancellationKey',
      component: () => import('@/views/anonymousPages/CancelSelfProtection'),
      meta: {
        allowAnonymous: true,
        allowConfidant: true,
        allowCustomer: true,
        layout: WaveLayout,
      },
    },
    {
      name: 'InstallApp',
      path: '/install-app',
      component: () => import('@/views/anonymousPages/InstallApp'),
      meta: {
        allowAnonymous: true,
        allowConfidant: true,
        allowCustomer: true,
      }
    },
    {
      name: 'Contacts',
      path: '/contacts',
      component: () => import('@/views/customerPages/Contacts'),
      meta: {
        allowAnonymous: false,
        allowConfidant: false,
        allowCustomer: true,
      },
    },
    {
      name: 'Confidants',
      path: '/confidants',
      component: () => import('@/views/customerPages/Confidants'),
      meta: {
        allowAnonymous: false,
        allowConfidant: false,
        allowCustomer: true,
      },
    },
    {
      name: 'AccountSettings',
      path: '/settings',
      component: () => import('@/views/customerPages/AccountSettings'),
      meta: {
        allowAnonymous: false,
        allowConfidant: false,
        allowCustomer: true,
        allowAdmin: true  // Add this to explicitly allow admin access
      },
    },
    {
      name: 'Templates',
      path: '/templates',
      component: () => import('@/views/customerPages/Templates'),
      meta: {
        allowAnonymous: false,
        allowConfidant: false,
        allowCustomer: true,
      },
    },
    {
      name: 'EmergencyNotification',
      path: '/emergency-notification',
      component: () => import('@/views/confidantPages/EmergencyNotification'),
      meta: {
        allowAnonymous: false,
        allowConfidant: true,
        allowCustomer: false,
      },
    },
    {
      name: 'Imprint',
      path: '/imprint',
      component: () => import('@/views/anonymousPages/Imprint'),
      meta: {
        allowAnonymous: true,
        allowConfidant: true,
        allowCustomer: true,
      },
    },
    {
      name: 'TermsOfUse',
      path: '/terms-of-use',
      component: () => import('@/views/anonymousPages/TermsOfUse'),
      meta: {
        allowAnonymous: true,
        allowConfidant: true,
        allowCustomer: true,
      },
    },
    {
      name: 'Privacy',
      path: '/privacy',
      component: () => import('@/views/anonymousPages/Privacy'),
      meta: {
        allowAnonymous: true,
        allowConfidant: true,
        allowCustomer: true,
      },
    },
    {
      name: 'Login',
      path: '/login',
      component: () => import('@/views/anonymousPages/LoginPage'),
      meta: {
        allowAnonymous: true,
        allowConfidant: false,
        allowCustomer: false,
        layout: WaveLayout,
      },
    },
    {
      name: 'ForgetPassword',
      path: '/forget-password',
      component: () => import('@/views/anonymousPages/RequestPasswordPage.vue'),
      meta: {
        allowAnonymous: true,
        allowConfidant: false,
        allowCustomer: false,
        layout: WaveLayout,
      },
    },
    {
      name: 'Register',
      path: '/register',
      component: () => import('@/views/register/Registration'),
      meta: {
        allowAnonymous: true,
        allowConfidant: true,
        allowCustomer: true,
        layout: WaveLayout,
      },
    },
    {
      path: '/imprint',
      redirect: {name: 'Imprint'}
    },
    {
      path: '/termsOfUse',
      redirect: {name: 'TermsOfUse'}
    },
    {
      path: '/privacy',
      redirect: {name: 'Privacy'}
    },
    {
      path: '/register',
      redirect: {name: 'Register'}
    },
    {
      name: 'Admin',
      path: '/admin',
      component: () => import('@/views/customerPages/adminPages/Admins'),
      meta: {
        allowAnonymous: false,
        allowConfidant: false,
        allowCustomer: false,  // Change to false
        allowAdmin: true,      // Add this to explicitly require admin
        requiresAdmin: true    // Add this as an extra check
      },
    },
    {
      path: '*',
      component: () => import('@/views/anonymousPages/PageNotFound'),
      meta: {
        layout: 'div',
      },
    },
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = sessionStorage.token
  const isCustomer = isLoggedIn && Boolean(sessionStorage.isCustomer === 'true')
  const isConfidant = isLoggedIn && !isCustomer
  const isAdmin = isLoggedIn && Boolean(sessionStorage.isAdmin === 'true')
  const required2FA = sessionStorage.required2FA

  // Handle direct access to login and register when logged out
  if ((to.path === '/login' || to.path === '/register') && !isLoggedIn) {
    return next()
  }

  // Add check for admin-only routes - redirect to Contacts if logged in, Login if not
  if (to.meta.requiresAdmin && !isAdmin) {
    next({ name: isLoggedIn ? 'Contacts' : 'Login' })
    return
  }

  if (!to.meta.allowAnonymous && !isLoggedIn) { // not logged in, trying to access non-anonymous page
    next({ name: 'Login' })
  } else if (to.path === '/2fa' && !required2FA) {
    next({ name: 'Login' })
  } else if (to.path !== '/2fa' && required2FA) {
    next({ name: '2fa' })
  } else if (isAdmin && !['Admin', 'AccountSettings', 'Login'].includes(to.name)) { 
    // If user is admin and trying to access any page other than Admin, AccountSettings or Login
    next({ name: 'Admin' })
  } else if (to.path === '/login' || to.path === '/register') { // logged in trying to access login or register page
    if (isLoggedIn) {  // Only redirect if logged in
      next({ name: isAdmin ? 'Admin' : (isCustomer ? 'Contacts' : 'EmergencyNotification') })
    } else {
      next()  // Allow access if not logged in
    }
  } else if (isConfidant && to.meta.allowCustomer && !to.meta.allowConfidant) { // logged in as confidant trying to access customer pages
    next({ name: 'EmergencyNotification' })
  } else if (isCustomer && !to.meta.allowCustomer && to.meta.allowConfidant) { // logged in as customer trying to access confidant pages
    next({ name: 'Contacts' })
  } else {
    if (!to.meta.layout) {
      if (isCustomer) {
        to.meta.layout = CustomerPageLayout
      } else if (isConfidant) {
        to.meta.layout = ConfidantPageLayout
      } else {
        to.meta.layout = AnonymousPageLayout
      }
    }
    next()
  }

  if (to.name) { // we check if the route exist
    recordPageVisit(to.name) // if it exist we store it by the name
  } else {
    recordPageVisit(to.path) // if not we store it by the path
  }
})

function recordPageVisit (path) {
  WebActivityService.registerVisitRecord({ path: path, clientTimeCreatedAt: new Date() })
}

export default router
